@import 'src/styles/functions';
@import 'src/styles/mixins';

.wrap {
  display: flex;
}

.item {
  display: flex;
  color: color('gray4');

  &:not(:last-child) {
    @include use-spacings(margin-right, 's');
  }

  &:hover {
    color: color('main');
    cursor: pointer;
  }
}
