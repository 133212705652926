@import '../../../styles/variables';
@import '../../../styles/functions';
@import '../../../styles/mixins';

.button {
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
}

.button-filled {
  background: color('main');

  &:hover {
    background: color('buttonhover');
  }

  &:active {
    background: color('buttonpressed');
  }
}

.button-outlined {
  color: color(mainblack);
  background: none;
  border: 1px solid color(mainblack);
}

.size-s {
  height: 32px;
}

.size-m {
  height: 56px;
}

.size-s,
.size-m {
  font-size: 18px;
  line-height: 24px;
}

.size-l {
  height: 64px;
  padding: 0 16px 1px;
  font-size: 24px;
  line-height: 32px;
}

.button-link {
  &.size-s,
  &.size-m,
  &.size-l {
    height: auto;
    padding: 0;

    @include extra-small-text();
    @include link-style();
  }

  background: none;
}

.disabled {
  cursor: initial;
  background: color('disabled');

  &:hover {
    background: color('disabled');
  }
}

@media (min-width: $mobile-size) {
  .button {
    width: auto;
  }

  .size-s {
    padding: 0 16px 1px;
  }

  .size-m {
    padding: 0 32px 1px;
  }

  .size-l {
    padding: 0 50px 1px;
  }

  .button-link {
    &.size-s,
    &.size-m,
    &.size-l {
      padding: 0;
    }
  }
}
