@import 'src/styles/variables';
@import 'src/styles/functions';
@import 'src/styles/mixins';

.menu {
  column-count: 1;

  @include use-spacings(margin-bottom, 'xl');

  @media (min-width: $extra-mobile-size) {
    column-count: 2;
  }

  @media (min-width: $tablet-size) {
    column-count: 3;
  }
}

.menu-item {
  display: block;

  @include h5-text();
  @include use-spacings(margin-bottom, 'm');

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: color(main);
  }
}

.menu-sub-item {
  opacity: 0.5;
}

.controls {
  @include use-spacings(padding-bottom, 'xl');
  @include flex(center, center, column);

  @media (min-width: $extra-mobile-size) {
    display: block;
    column-count: 2;
  }

  @media (min-width: $mobile-size) {
    @include flex();

    align-items: stretch;
    margin-top: auto;
    column-count: initial;
  }

  @media (min-width: $tablet-size) {
    margin-top: initial;
  }
}

.hot-line {
  @include use-spacings(margin-bottom, 'm');
  @include flex(flex-end, flex-end, column);

  h5 {
    @include use-spacings(padding-bottom, 'xs');
  }

  span {
    @include extra-small-text();
  }

  @media (min-width: $mobile-size) {
    margin-bottom: initial;
  }

  @media (max-width: 868px) {
    @include use-spacings('padding-top', 'm');
  }
}

.socials-and-login {
  page-break-inside: avoid;

  @include flex(space-between, center);

  @media (max-width: 868px) {
    flex-direction: column;
  }

  @media (min-width: $mobile-size) {
    flex: 1;

    @include use-spacings(padding-left, 'xl');
  }
}

.login-as {
  @include h5-text();
  @include use-spacings(margin-bottom, 'l');

  @media (min-width: $mobile-size) {
    margin-bottom: initial;
  }
}

@media (max-width: $extra-mobile-size) {
  .login-as {
    width: 100%;
  }

  .login-as-links {
    display: flex;
    flex-direction: column;

    a {
      padding-right: 0;
      color: color('black50');

      @include use-spacings(padding-top, 's');
    }
  }
}
