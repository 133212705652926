@import 'src/styles/variables';
@import 'src/styles/functions';
@import 'src/styles/mixins';

.option {
  display: flex;
  align-items: center;
  min-height: 44px;
}

.paper {
  margin: 0;
  overflow: hidden;
  background: #fff;

  // stylelint-disable-next-line
  :global(.MuiAutocomplete-listbox) {
    border-right: 1px solid color(black50);
    border-left: 1px solid color(black50);
  }

  &[data-dropdown-placement="bottom"] {
    // stylelint-disable-next-line
    :global(.MuiAutocomplete-listbox) {
      border-top: 0;
      border-bottom: 1px solid color(black50);
      border-radius: 0 0 $base-border-radius $base-border-radius;
    }

    border-radius: 0 0 $base-border-radius $base-border-radius;
  }

  &[data-dropdown-placement="top"] {
    // stylelint-disable-next-line
    :global(.MuiAutocomplete-listbox) {
      border-top: 1px solid color(black50);
      border-bottom: 0;
      border-radius: $base-border-radius $base-border-radius 0 0;
    }

    border-radius: $base-border-radius $base-border-radius 0 0;
  }
}
