@import 'src/styles/variables';
@import 'src/styles/functions';
@import 'src/styles/mixins';

.wrap {
  position: fixed;
  top: 0;
  right: -100vw;
  bottom: 0;
  left: 100vw;
  z-index: 50;
  transition: 0.3s ease-in;

  * {
    box-sizing: initial;
  }

  &.enter,
  &.entered {
    right: 0;
    left: 0;
    transition: 0.3s ease-out;
  }

  &.exit,
  &.exited {
    right: -100vw;
    left: 100vw;
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}

.container-wrap {
  min-height: 100%;
  max-height: 100%;
  background: color('mainwhite');

  @media (min-width: $mobile-size) {
    min-height: initial;
  }
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;

  @include use-spacings(margin-bottom, 'l');

  @media (min-width: $extra-mobile-size) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
  }
}

.copy-menu {
  display: flex;
  align-items: center;
  height: $mobile-menu-height;
  min-height: $mobile-menu-height;
  max-height: $mobile-menu-height;

  @include use-spacings(margin-bottom, 'l');
}

.logo {
  width: $mobile-menu-logo-width;
}

.rightControls {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
