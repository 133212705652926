@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/functions';

.root {
  width: 100%;

  @include use-spacings(padding-left, s);
  @include use-spacings(padding-right, s);
  @include use-spacings(padding-top, xl);
  @include use-spacings(padding-bottom, xl);
}

@media (min-width: $mobile-size) {
  .root {
    max-width: 768px;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: $mobile-size) {
  .root {
    min-width: 768px;
  }
}
