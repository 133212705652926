@import 'src/styles/variables';
@import 'src/styles/functions';
@import 'src/styles/mixins';

.wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 56px;
  padding: 8px 16px 6px;
  background-color: color(mainwhite);
  border-radius: 16px 16px 0 0;
  box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.1);

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  @media (min-width: $mobile-size) {
    display: none;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: color('blueDark');
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }

  @media (min-width: $extra-mobile-size) {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.active {
  color: color(main);
}

.hidden {
  transform: translateY(100%);
}
