@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/mixins';

.root {
  display: flex;
  flex-direction: column;
}

.step {
  width: 100%;
  text-align: center;
}

.content {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar {
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  overflow: hidden;
  color: color(main);
  background: color(bluealice);
  border-radius: $base-border-radius;

  @include use-spacings(margin-top, m);
  @include use-spacings(margin-bottom, m);
  @include use-spacings(padding-left, s);
  @include use-spacings(padding-right, s);
}

.icon {
  height: 30px;

  @include use-spacings(margin-right, s);
}

.track {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  background: color(pacificblue);
  transition: width $base-transition;
}

@media (min-width: $mobile-size) {
  .root {
    flex-direction: row;
    align-items: center;
  }

  .bar {
    flex: 1;
    margin-top: 0;
  }

  .step {
    width: initial;

    @include use-spacings(margin-right, l);
  }
}
