@import '../../../styles/functions';

.wrapper {
  position: relative;
  width: 100%;
}

.image-not-load {
  flex: 0;
  width: 0;

  > div {
    position: absolute;
    top: 0;
    bottom: 0;
  }
}

.image-wrap {
  position: relative;
  width: 100%;
}

.fallback {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(8px);
}
