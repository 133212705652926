@import "src/styles/mixins";

@keyframes lds-ring-default {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading {
  @include flex(center);

  width: 100%;
  min-height: 200px;
}

.ring {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 64px;

  div {
    position: absolute;
    box-sizing: border-box;
    display: block;
    width: 64px;
    height: 64px;
    margin: 0;
    border: 8px solid #3aa2dd;
    border-color: #3aa2dd transparent transparent;
    border-radius: 50%;
    animation: lds-ring-default 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    &:nth-child(1) { animation-delay: -0.45s; }
    &:nth-child(2) { animation-delay: -0.3s; }
    &:nth-child(3) { animation-delay: -0.15s; }
  }
}

.min {
  width: auto;
  min-height: auto;

  .ring {
    @include flex(center);

    width: 30px;
    height: 30px;

    div {
      width: 25px;
      height: 25px;
      border-width: 4px;
    }
  }
}
