@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.wrap {
  :global .rangeslider {
    @include use-spacings(margin-top, xs);
    @include use-spacings(margin-bottom, 20px);

    position: relative;
    display: block;
    -ms-touch-action: none;
    touch-action: none;
    box-shadow: inset 0 1px 4px color('border');
  }

  :global .rangeslider-horizontal {
    height: 4px;
  }

  :global .rangeslider__fill {
    top: 0;
    height: 100%;
    background-color: color('main');
  }

  :global .rangeslider__handle {
    position: absolute;
    top: 50%;
    z-index: 10;
    display: inline-block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    background: color('main');
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  :global .rangeslider__labels {
    position: relative;
  }

  :global .rangeslider__label-item {
    position: absolute;
    top: 12px;
    display: inline-block;
    color: color('black50');
    cursor: pointer;
    transform: translate3d(-50%, 0, 0);

    @include extra-small-text();

    &::after {
      position: absolute;
      top: -16px;
      left: 50%;
      z-index: 5;
      width: 6px;
      height: 4px;
      content: '';
      background: color('black50');
    }

    &:last-child {
      left: 0 !important;
      transform: none;
    }

    &:last-child::after {
      left: 0;
    }

    &:first-child {
      white-space: nowrap;
      transform: translate3d(-75%, 0, 0);
    }

    &:first-child::after {
      left: calc(75% + 3px);
    }
  }
}

.inputWrap {
  @include flex();

  svg {
    width: 24px;
    height: 24px;
  }
}

.result {
  width: calc(100% - 24px);
  background: transparent;
  border: none;
  outline: unset;

  @include h4-text();
}
