@import 'src/styles/functions';
@import 'src/styles/variables';
@import 'src/styles/mixins';

.button {
  color: color('mainblack');
  background: none;
  border: 1px solid color('mainblack');
  border-radius: $base-border-radius;

  @include medium-bold-text();
  @include use-spacings(padding, 3px, 's', 4px);

  &:hover {
    color: color('buttonhover');
    cursor: pointer;
    border-color: color('buttonhover');
  }

  &:active {
    color: color('buttonpressed');
    border-color: color('buttonpressed');
  }

  &:disabled {
    color: color('disabled');
    pointer-events: none;
    border-color: color('disabled');
  }
}
