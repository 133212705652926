@import '../../../../../../../../styles/mixins';
@import '../../../../../../../../styles/functions';

.controls {
  display: flex;
  flex-direction: column;
}

.submit-button {
  @include flex(center);
  @include use-spacings(margin-top, s);
}

.fullWidth {
  width: 100%;
}

.privacy {
  color: color(black50);
  text-align: center;

  @include small-text();
  @include use-spacings(margin-top, l);
}

.extra {
  color: color(black50);

  @include small-text();
  @include use-spacings(margin-top, s);
}

@media (min-width: $mobile-size) {
  .controls {
    @include flex-columns($flex-direction: row);
  }

  .privacy {
    padding-top: 10px;
    text-align: left;
  }

  .submit-button {
    @include use-spacings(margin-top, l);
  }
}
