@import 'src/styles/variables';
@import 'src/styles/functions';
@import 'src/styles/mixins';

.wrap {
  position: fixed;
  top: 100vw;
  right: 0;
  bottom: -100vw;
  left: 0;
  z-index: 1000;
  transition: 0.3s ease-in;

  * {
    box-sizing: initial;
  }

  &.enter,
  &.entered {
    top: 55vw;
    bottom: 0;
    transition: 0.3s ease-out;
  }

  &.exit,
  &.exited {
    top: 100vw;
    bottom: -100vw;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
}

.container-wrap {
  min-height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background: color('mainwhite');
  border-radius: 16px 16px 0 0;
  box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.1);

  @media (min-width: $mobile-size) {
    min-height: initial;
  }
}

.container {
  display: flex;
  flex-direction: column;
  padding: 16px 18px;
}

.copy-menu {
  display: flex;
  align-items: center;
}

.rightControls {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
