@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

$input-range-slider-height: 24px;
$input-range-slider-width: 24px;
$input-range-slider-transition: left 0.1s ease-out;
$input-range-slider-container-transition: left 0.1s ease-out;
$input-range-track-height: 8px;
$input-range-track-transition: left 0.1s ease-out, width 0.1s ease-out;

.wrapper {
  padding-bottom: 32px;
}

.input-range {
  position: relative;
  height: $input-range-track-height;
  margin: -$input-range-track-height auto 0;
  background: color(black50);
}

.track {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin-top: -0.5 * $input-range-track-height;
}

.active-track {
  position: relative;
  display: block;
  height: $input-range-track-height;
  cursor: pointer;
  background: color(wheat);
  transition: background $base-transition;
}

.slider {
  position: absolute;
  top: 50%;
  display: block;
  width: $input-range-slider-width;
  height: $input-range-slider-height;
  margin-top: calc(#{$input-range-slider-height} / -2 + #{$input-range-track-height} / -2);
  margin-left: calc(#{$input-range-slider-width} / -2);
  cursor: pointer;
  background: color(wheat);
  border-radius: 100%;
  outline: none;
  transition: background $base-transition;
  appearance: none;

  &::before {
    position: absolute;
    top: calc($input-range-slider-height / -2);
    left: calc($input-range-slider-height / -2);
    width: calc($input-range-slider-height * 2);
    height: calc($input-range-slider-height * 2);
    content: '';
    background: inherit;
    border-radius: 100%;
    opacity: 0;
    transition: $base-transition;
  }

  &:active::before,
  &:focus::before {
    opacity: 0.3;
  }
}

.label-container {
  position: relative;
}

.disabled-track {
  pointer-events: none;

  .active-track,
  .slider {
    background: color(disabled);
  }
}

.value-label {
  display: none;
}

.min-label,
.max-label {
  position: absolute;
  top: 18px;

  @include light-text();
  @include body-text();
}

.min-label {
  left: 0;
}

.max-label {
  right: 0;
}

.result {
  width: 100%;
  height: 64px;
  padding: 0 16px;
  background: color(oldlace);
  border: none;
  border-radius: $base-border-radius $base-border-radius 0 0;
  outline: none;

  @include flex();
  @include h5-text();
}

@media (min-width: $mobile-size) {
  .slider-container {
    transition: $input-range-slider-container-transition;
  }

  .active-track {
    transition: $input-range-track-transition;
  }

  .slider {
    transition: $input-range-slider-transition;
  }
}
