@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/mixins';

.root {
  @include use-spacings(padding, l);

  border-radius: $base-border-radius;
  box-shadow: $card-shadow;
}

.h2 {
  @include use-spacings(margin-bottom, m);
}

.h3 {
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;

  @include use-spacings(margin-bottom, l);
}

@media (max-width: $mobile-size) {
  .h3 {
    @include use-spacings(margin-bottom, m);
  }
}

.calculator-input-label {
  display: block;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: color(black50);

  @include use-spacings(margin-bottom, s);
}

.calculator-inputs-wrap {
  width: 100%;
}

.calculator-input {
  @include use-spacings(margin-top, l);
}

.agreements {
  padding-top: 27px;
}

body .input-range-result {
  background: color(bluealice);
}

body .track {
  background: color(pacificblue);
}

body .active-track {
  background: color(main);
}

@media (min-width: $tablet-size) {
  .calculator-inputs-wrap {
    @include flex-columns();
  }
}

.link {
  @include link-style();
}
