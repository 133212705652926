@import 'src/styles/variables';
@import 'src/styles/functions';
@import 'src/styles/mixins';

.title {
  @include h1-text();
}

.login-item {
  @include use-spacings(margin-top, s);

  p {
    @include use-spacings(padding-top, xs);
  }
}

.button {
  height: 54px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  border-radius: $base-border-radius;

  @include flex(center);
}

.button-green {
  background: color(green);
}

.button-blue {
  background: color(main);
}

.button-dark-grey {
  background: color(darkgrey);
}
