@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/mixins';

.hint {
  color: color(black50);
}

.step {
  @include use-spacings(padding-right, m);
}

.h2 {
  @include use-spacings(margin-bottom, m);
}

.back,
.esia {
  width: 100%;
}

.controls {
  display: flex;
  flex-direction: column-reverse;

  @include use-spacings(margin-top, l);

  .esia {
    padding: 0 24px 1px;
  }

  button {
    @include use-spacings(margin-bottom, m);

    &:first-child {
      @include use-spacings(margin-bottom, 0);
    }
  }
}

.image {
  @include use-spacings(margin-bottom, xs);
}

@media (min-width: $mobile-size) {
  .controls {
    flex-direction: row;

    button {
      @include use-spacings(margin-bottom, 0);
    }

    .back {
      width: auto;
      min-width: 260px;

      @include use-spacings(margin-right, m);
    }
  }
}
