@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/mixins';

.root {
  border-radius: $base-border-radius;
  box-shadow: $card-shadow;

  @include use-spacings(padding, l);
}

.h2 {
  @include use-spacings(margin-bottom, m);
}

.h3 {
  display: flex;
  flex-wrap: wrap;

  @include use-spacings(margin-bottom, m);
}

.h4 {
  @include use-spacings(margin-bottom, l);
}

@media (max-width: $mobile-size) {
  .h4 {
    @include use-spacings(margin-bottom, m);
  }
}
