@import 'src/styles/variables';
@import 'src/styles/functions';
@import 'src/styles/mixins';

.wrap {
  display: flex;
  flex-wrap: wrap;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  @include use-spacings(padding, 0, 8px);
  @include use-spacings(margin-bottom, xl);
}

.item {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: color(main);
  }

  .subMenu {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    align-items: center;
    width: 100%;

    @include use-spacings(padding-left, 36px);

    .subItem {
      width: 100%;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;

      &:active,
      &:hover {
        color: color(main);
      }
    }
  }
}

.controls {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #f2f2f2;

  @include use-spacings(padding, 26px, 8px, 10px);
}

.hotLine {
  h5 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

.button {
  @include use-spacings(padding, 0, 54px);

  margin-top: 16px;

  @media (min-width: $extra-mobile-size) {
    margin-top: 0;
  }
}
